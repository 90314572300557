import React from "react";
import { useSelector } from "react-redux";
import Translate from "../../../Components/Multilanguage/Translate";

const PlanDetails = ({ plan, onBack }) => {

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;

    return (
        <div className="plan-details-container">
          {plan?.logo &&
            <div className="plan-banner">
                <img src={plan?.logo} alt={plan?.subscription_name} />
            </div>
           }
            <div className="plan-content">
                <h1 className="plan-title">{plan?.subscription_name}</h1>
                <p className="plan-description">{plan?.description}</p>
                <div className="plan-price">
                    <strong>{plan?.symbol}</strong>{plan?.price} {plan?.currency}
                </div>
                <a className={`back-button ${template === 'TEMPLATE_9' ? 'template-9' : ''}`} onClick={() => onBack(onBack)}>
                    <Translate textKey={'subscription_title'} />
                </a>
            </div>
        </div>
    );
};

export default PlanDetails;